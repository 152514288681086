export default {
	getUserList: {
		url: '/User/getUserList',
		method: 'get',
		description: '获取会员列表'
	},
	getUserLogList: {
		url: '/User/getUserLogList',
		method: 'get',
		description: '获取会员查询日志列表'
	},
}
