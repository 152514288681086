import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const allStore = require.context('@/store/data', false, /^.*\.js$/);
const allConfig = require.context('@/config', false, /^.*\.json$/);

let configs = {}
allConfig.keys().forEach(fileName => {
	configs[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = allConfig(fileName);
});

let stores = {}
allStore.keys().forEach(fileName => {
	let comp = allStore(fileName).default;
	let name = fileName.replace(/^\.\/(.*)\.\w+$/, '$1');
	comp.namespaces = true;
	switch (name){
		case 'config':
			comp.state = configs;
			break;
	}
	stores[name] = comp;
})
export default new Vuex.Store({
	modules:{
		...stores
	}
})