export default {
	getConfigForClassify: {
		url: '/config/getConfigForClassify',
		method: 'get',
		description: '获取配置-分类'
	},
	getSystemConfig: {
		url: '/config/getSystemConfig',
		method: 'get',
		description: '获取系统配置'
	},
	updateConfigForClassify: {
		url: '/config/updateConfigForClassify',
		method: 'post',
		description: '修改配置-分类'
	},
}