<template>
	<div id="app">
		<default v-if="layout_default() && show"/>
		<menuList v-if="!layout_default() && show"/>
	</div>
</template>

<script>
import defaults from '@/layout/default';
import menuList from '@/layout/menu';
export default{
	name: 'App',
	components:{
		'default':defaults,
		'menuList':menuList,
	},
	data(){
		return{
			show:false,
		}
	},
	async created() {
		let id = this.$cookie.get('id');
		let time = this.$cookie.get('time');
		let token = this.$cookie.get('token');
		let auth = this.$cookie.get('auth');
		let account = this.$cookie.get('account');
		let end = this.$cookie.get('end');
		let current_path = location.origin+location.pathname;
		if (current_path.indexOf("/login") === -1){
			if(this.empty(time) || this.empty(token) || this.empty(auth) || this.empty(id) || this.empty(account) || this.empty(end)){
				location.replace(this.buildUrl("/login/",{
					location:location.pathname
				}));
				return false;
			}
			if(end !== this.$md5(`id=${id}|time=${time}|token=${token}|auth=${auth}|account=${account}`)){
				location.replace(this.buildUrl("/login/",{
					location:location.pathname
				}));
				return false;
			}else{
				// TODO:添加真实登录验证接口，并将执行到当前步骤且登录失败的IP记录并做对应限制处理

			}
		}
		let config = await this.request.send('config/getSystemConfig',{});
		this.$store.state.system = config.data;
		this.show = true;
	},
	methods:{
		layout_default:function (){
			let url = location;
			let defaults = this.$store.state.router.layout.default;
			let status = false;
			defaults.forEach(item=>{
				if(url.pathname.indexOf(item) !== -1){
					status = true;
				}
			});
			return status;
		},
	}
}
</script>

<style lang="scss">


</style>
