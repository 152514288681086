export default {
	getDataList: {
		url: '/data/getDataList',
		method: 'get',
		description: '获取数据列表'
	},
	getDataDetail: {
		url: '/data/getDataDetail',
		method: 'get',
		description: '获取数据'
	},
	updateData: {
		url: '/data/updateData',
		method: 'post',
		description: '修改数据'
	},
	deleteData: {
		url: '/data/deleteData',
		method: 'post',
		description: '删除数据'
	},
}
