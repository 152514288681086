export default {
	state:{
		history:[
			"/"
		],
		asyncTitle:"···",
		suffix:"",
		prefix:"",
		layout:{
			default:[
				"/login"
			]
		},
		secondMenuList:[],
		tabBarList:[
			{
				name:"/",
				title:"首页"
			}
		],
		tabBarActive:'',
		activeIndex:0,
		tabBarTitle:[],
	},
	actions:{
		history({ commit },data){
			commit('history',data);
		},
		asyncTitle({ commit },data){
			commit('asyncTitle',data);
		},
		suffix({ commit },data){
			commit('suffix',data);
		},
		prefix({ commit },data){
			commit('prefix',data);
		},
		secondMenuList({ commit },data){
			commit('secondMenuList',data);
		},
		tabBarList({ commit },data){
			commit('tabBarList',data);
		},
		tabBarActive({ commit },data){
			commit('tabBarActive',data);
		},
		activeIndex({ commit },data){
			commit('activeIndex',data);
		},
		tabBarTitle({ commit },data){
			commit('tabBarTitle',data);
		},
	},
	mutations:{
		history(state,data){
			state.history = data;
		},
		asyncTitle(state,data){
			state.asyncTitle = data;
		},
		suffix(state,data){
			state.suffix = data;
		},
		prefix(state,data){
			state.prefix = data;
		},
		secondMenuList(state,data){
			state.secondMenuList = data;
		},
		tabBarList(state,data){
			state.tabBarList = data;
		},
		tabBarActive(state,data){
			state.tabBarActive = data;
		},
		activeIndex(state,data){
			state.activeIndex = data;
		},
		tabBarTitle(state,data){
			state.tabBarTitle = data;
		},
	},
	getters:{

	}
}