<template>
	<div class="bar" :style="`width:${!empty(secondMenuList) ? 'calc(100% - 266px);' : 'calc(100% - 64px);'}`">
		<div class="status">
			<div class="left-panel">
				<ul>
					<li>
						<i class="el-icon-s-fold"></i>
					</li>
					<li class="current-panel">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item v-for="item in tabBarTitle">{{ item }}</el-breadcrumb-item>
						</el-breadcrumb>
					</li>
				</ul>
			</div>
			<div class="right-panel">
				<ul>
					<li>
						<i class="el-icon-unlock"></i>
					</li>
					<li>
						<i class="el-icon-search"></i>
					</li>
					<li>
						<el-badge  :value="0">
							<i class="el-icon-bell"></i>
						</el-badge>
					</li>
					<li>
						<i class="el-icon-s-open"></i>
					</li>
					<li>
						<i class="el-icon-refresh-left"></i>
					</li>
					<li>
						<el-dropdown @command="handleCommand" size="medium " class="userinfo" placement="bottom-start">
							<div>
								<div class="logo">
									<img src="https://i.gtimg.cn/club/item/face/img/2/16022_100.gif" alt="">
								</div>
								<span>{{account.nickname}}</span>
								<i style="margin-left: 5px" class="el-icon-arrow-down"></i>
							</div>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-if="false" icon="el-icon-user">个人中心</el-dropdown-item>
								<el-dropdown-item command="loginOut" icon="el-icon-switch-button">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</li>
				</ul>
			</div>
		</div>
		<div class="table-bar">
			<el-tabs v-model="tIndex" type="card" @tab-click="handleBarClick" @tab-remove="removeTab">
				<el-tab-pane :closable="item.name !== '/'"
					v-for="(item, index) in tabBarList"
					:key="item.name"
					:name="item.name">
				    <span class="bar-name" slot="label">
					    <i class="el-icon-s-grid"></i>
					    <span>{{ item.title }}</span>
				    </span>
				</el-tab-pane>
			</el-tabs>
			<div class="bar-operation">
				<el-dropdown @command="handleCommand" size="medium " class="operationInfo" placement="bottom-start">
					<span>
		                 <i class="el-icon-menu"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="refresh" icon="el-icon-refresh">刷新</el-dropdown-item>
						<el-dropdown-item command="closeOther" icon="el-icon-close">关闭其他</el-dropdown-item>
						<el-dropdown-item command="closeLeft" icon="el-icon-back">关闭左侧</el-dropdown-item>
						<el-dropdown-item command="closeRight" icon="el-icon-right">关闭右侧</el-dropdown-item>
						<el-dropdown-item command="closeAll" icon="el-icon-close">全部关闭</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    name: "headers",
	data(){
		let account = JSON.parse(this.$cookie.get('account'));
		return{
			editableTabs: [],
			tIndex:'',
			account:account
		}
	},
	computed:{
		menuList:function (){
			return this.$store.state.config.menuList;
		},
		secondMenuList:function (){
			return this.$store.state.router.secondMenuList;
		},
		tabBarList:function (){
			return this.$store.state.router.tabBarList;
		},
		tabBarActive:function (){
			return this.$store.state.router.tabBarActive;
		},
		tabBarTitle:function (){
			return this.$store.state.router.tabBarTitle;
		},
	},
	watch:{
		tabBarActive:function (e){
			this.tIndex = e;
		}
	},
	methods:{
		loginOut:function (){
			this.$cookie.remove('id');
			this.$cookie.remove('time');
			this.$cookie.remove('token');
			this.$cookie.remove('auth');
			this.$cookie.remove('account');
			this.$cookie.remove('end');
			location.reload()
		},
		refresh:function (){

		},
		closeOther:function (){
			let targetName = this.tabBarActive;
			let newArr = [];
			let that = this;
			for(let i =0;i<this.tabBarList.length;i++){
				if(that.tabBarList[i].name === targetName || that.tabBarList[i].name === '/'){
					newArr.push(this.tabBarList[i])
				}
			}
			that.$store.dispatch('tabBarList',newArr);
		},
		closeLeft:function (){
			let targetName = this.tabBarActive;
			let newArr = [{
				name:"/",
				title:"首页"
			}];
			let that = this;
			let start = false;
			for(let i =0;i<this.tabBarList.length;i++){
				if(that.tabBarList[i].name === targetName){
					start = true;
				}
				if(start){
					newArr.push(this.tabBarList[i])
				}
			}
			that.$store.dispatch('tabBarList',newArr);
		},
		closeRight:function (){
			let targetName = this.tabBarActive;
			let newArr = [];
			let that = this;
			let start = true;
			for(let i =0;i<this.tabBarList.length;i++){
				if(start){
					newArr.push(this.tabBarList[i])
				}
				if(that.tabBarList[i].name === targetName){
					start = false;
				}
			}
			that.$store.dispatch('tabBarList',newArr);
		},
		closeAll:function (){
			this.$store.dispatch('tabBarList',[{
				name:"/",
				title:"首页"
			}]);
			this.$store.dispatch('tabBarActive','/');
			if(this.$route.fullPath !== '/'){
				this.$router.push({
					path:'/'
				});
			}
		},
		handleCommand:function (command){
			this[command]();
		},
		handleBarClick:function (tab){
			let path = this.tabBarList[tab.index];
			if(this.$route.fullPath !== path.name){
				this.$router.push({
					path:path.name
				});
			}
		},
		removeTab:function (targetName) {
			let newArr = [];
			let that = this;
			for(let i =0;i<this.tabBarList.length;i++){
				if(this.tabBarList[i].name !== targetName){
					newArr.push(this.tabBarList[i])
				}
			}
			that.$store.dispatch('tabBarList',newArr);
			if(targetName === this.$route.fullPath){
				this.$router.push({
					path:newArr[newArr.length-1].name,
				})
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.bar{
	transition: all .2s;
	user-select: none;
	background: #fff;
	position: fixed;
	top: 0;
	right: 0;
	width: calc(100% - 266px);
	height: 110px;
	.status{
		padding: 0 20px;
		height: 60px;
		border-bottom: 1px solid #f6f6f6;
		.left-panel{
			position: absolute;
			left: 20px;
			top: 0;
			width: calc(50% - 20px);
			height: 60px;
			::v-deep{
				.el-breadcrumb{
					line-height: 60px;
				}
			}
		}
		.right-panel{
			position: absolute;
			right: 20px;
			top: 0;
			width: calc(50% - 20px);
			height: 60px;
		}

		.left-panel,.right-panel {
			>ul{
				>li{
					line-height: 60px;
					height: 60px;
					display: inline-block;
					vertical-align: middle;
					i{
						vertical-align: middle;
						font-size: 16px;
					}
					::v-deep{
						.el-badge{
							line-height: 20px;
						}
					}
				}
			}
		}
		.left-panel{
			.current-panel{
				margin-left: 15px;
				span{
					vertical-align: middle;
					color: #515a6e;
				}
			}
		}
		.right-panel{
			ul{
				text-align: right;
				li{
					cursor: pointer;
					margin: 0 10px;
					.userinfo{
						::v-deep{
							.el-dropdown-selfdefine{
								height: 100%;
								display: inline-block;
							}
						}
						height: 100%;
						.i-icon{
							transition: all .6s;
						}
						.logo{
							display: inline-block;
							width: 40px;
							height: 40px;
							border-radius: 20px;
							margin-right: 5px;
							img{
								width: 100%;
								height: 100%;
								border-radius: 20px;
							}
						}
					}
				}
				li:hover .userinfo .i-icon{
					transform:rotate(180deg)
				}
			}
		}
	}
	.table-bar{
		position: relative;
		padding: 0 20px;
		height: 50px;
		::v-deep{

			.el-tabs{
				width: calc(100% - 70px);
				position: absolute;
				bottom: 0;
			}
			.el-tabs__header{
				border: none;
				margin-bottom: 0;
			}
			.el-tabs__nav {
				border: none;
			}
			.is-active{
				background: #e8f4ff;
				color: #1890ff !important;
			}
			.is-active:hover{
				background: #e8f4ff!important;
			}

			.el-tabs__item{
				padding: 0 30px !important;
				transition: all .3s;
				margin-right: -25px;
				mask-repeat: no-repeat;
				-webkit-mask: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANoAAAAkBAMAAAAdqzmBAAAAMFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlTPQ5AAAAD3RSTlMAr3DvEM8wgCBA379gj5//tJBPAAAAnUlEQVRIx2NgAAM27fj/tAO/xBsYkIHyf9qCT8iWMf6nNQhAsk2f5rYheY7Dnua2/U+A28ZEe8v+F9Ax2v7/F4DbxkUH2wzgtvHTwbYPo7aN2jZq26hto7aN2jZq25Cy7Qvctnw62PYNbls9HWz7S8/G6//PsI6H4396gAUQy1je08W2jxDbpv6nD4gB2uWp+J9eYPsEhv/0BPS1DQBvoBLVZ3BppgAAAABJRU5ErkJggg==);
				mask: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANoAAAAkBAMAAAAdqzmBAAAAMFBMVEVHcEwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlTPQ5AAAAD3RSTlMAr3DvEM8wgCBA379gj5//tJBPAAAAnUlEQVRIx2NgAAM27fj/tAO/xBsYkIHyf9qCT8iWMf6nNQhAsk2f5rYheY7Dnua2/U+A28ZEe8v+F9Ax2v7/F4DbxkUH2wzgtvHTwbYPo7aN2jZq26hto7aN2jZq25Cy7Qvctnw62PYNbls9HWz7S8/G6//PsI6H4396gAUQy1je08W2jxDbpv6nD4gB2uWp+J9eYPsEhv/0BPS1DQBvoBLVZ3BppgAAAABJRU5ErkJggg==);
				mask-size: 100%  100%;
				-webkit-mask-size: 100% 100%;
				height: 40px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				line-height: 40px;
				display: inline-block;
				list-style: none;
				font-size: 14px;
				font-weight: 500;
				color: #303133;
				position: relative;
				border:none;
				.bar-name{
					span{
						font-size: 14px;
					}
				}
			}
			.el-tabs__item:hover{
				padding: 0 30px;
				color: #515a6e;
				background: #dee1e6;
			}
		}
		.bar-operation{
			text-align: center;
			position: absolute;
			right: 0;
			width: 50px;
			height: 50px;
			line-height: 50px;
			.operationInfo{
				display: inline-block;
			}
			i{
				vertical-align: middle;
				transition: all .3s;
				font-size: 18px;
				cursor: pointer;
			}
		}
		.bar-operation:hover i{
			color: #1890ff;
			display: inline-block;
			transform:rotate(135deg);
		}

	}

}
</style>
