export default [
	{
		path: '/data/',
		name: 'data-department',
		component: () => import('@/views/data')
	},
	{
		path: '/data/import',
		name: 'data-import',
		component: () => import('@/views/data/import')
	},
	{
		path: '/data/detail',
		name: 'data-detail',
		component: () => import('@/views/data/detail')
	}
]
