import Vue from 'vue'

// 判断数据是否为空
Vue.prototype.empty = function (data) {
	if (data !== undefined && data !== null && data !== '' && JSON.stringify(data) !== undefined && JSON.stringify(data) !== null && JSON.stringify(data) !== '{}' && JSON.stringify(data) !== '[]') {
		return false;
	}
	return true;
}

Vue.prototype.inArray = function (item,array){
	let is = false;
	for(let i = 0;i<array.length;i++){
		if(array[i] === item){
			is = true;
			break;
		}
	}
	return is;
}

// 构建完整地址
Vue.prototype.buildUrl = function (url = '', params = {}, exclude = []) {
	let urlArr = url.split('?');
	let origin = urlArr[0];
	let paramsArray = [];
	let param = '';
	if (urlArr.length === 2) {
		let param = urlArr[1];
		let paramsArr = param.split('&');
		for (let n = 0; n < paramsArr.length; n++) {
			let info = false;
			for (let i = 0; i < exclude.length; i++) {
				if (paramsArr[n].indexOf(exclude[i] + '=') !== -1) {
					info = true;
					break;
				}
			}
			if (!info) {
				paramsArray.push(paramsArr[n])
			}
		}
	}
	for (let item in params) {
		paramsArray.push(item + '=' + params[item])
	}
	for (let item of paramsArray) {
		param += item + '&';
	}
	param = param.substring(0, param.lastIndexOf('&'));
	return param === '' ? origin : origin + '?' + param;
}

// 获取表单数据
Vue.prototype.getForm = function (el){
	var d = {};
	let t = $(el).serializeArray();
	$.each(t, function() {
		d[this.name] = this.value;
	});
	return d;
}

// 获取当前时间戳
Vue.prototype.time = function () {
	let tmp = Date.parse(new Date()).toString();
	tmp = tmp.substr(0, 10);
	return tmp;
}


// 将时间戳转换为时间日期格式
Vue.prototype.formatDate = function (Unix,formate = 'Y-m-d h:i:s',complement = true,alike = false) {
	let current = new Date(Date.parse(new Date()));
	let current_year = current.getFullYear();
	let current_month = current.getMonth() + 1;
	let current_date = current.getDate();

	let timestamp = new Date(Unix * 1000);
	let year = timestamp.getFullYear();
	let month = timestamp.getMonth() + 1;
	let date = timestamp.getDate();
	let hour = timestamp.getHours();
	let minute = timestamp.getMinutes();
	let second = timestamp.getSeconds();

	if(alike && current_year === year){
		formate = formate.replace(/Y-/g,'');
	}else{
	}

	if(alike && current_year === year && current_month === month && current_date === date){
		formate = formate.replace(/m-d /g,'');
	}

	if(complement){
		month = month < 10 ? '0' + month : month;
		date = date < 10 ? '0' + date : date;
		hour = hour < 10 ? '0' + hour : hour;
		minute = minute < 10 ? '0' + minute : minute;
		second = second < 10 ? '0' + second : second;
	}


	formate = formate.replace(/Y/g,year);
	formate = formate.replace(/m/g,month);
	formate = formate.replace(/d/g,date);
	formate = formate.replace(/h/g,hour);
	formate = formate.replace(/i/g,minute);
	formate = formate.replace(/s/g,second);

	return formate;
};
Vue.prototype.time = function (){
	return Date.parse(new Date()) / 1000;
}

// 返回上一页
Vue.prototype.goBack = function (url="") {
	if(url === ""){
		this.$router.go(-1)
	}else{
		this.$router.push({
			path:url
		})
	}
}
