import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from "./lib/request";
Vue.prototype.request = new request(Vue.prototype.$axios);
Vue.prototype.$store = store;
const allPlugins = require.context('@/plugins', false, /^.*\.js$/);
allPlugins.keys().forEach(fileName => {
  allPlugins(fileName);
});
Vue.prototype.request = new request(Vue.prototype.$axios);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
