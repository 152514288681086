export default {
	checkin: {
		url: '/admin/checkin',
		method: 'post',
		description: '管理员登录'
	},
	getGroupAuthList: {
		url: '/admin/getGroupAuthList',
		method: 'get',
		description: '获取鉴权列表'
	},
	getAdminList: {
		url: '/admin/getAdminList',
		method: 'get',
		description: '获取管理员列表'
	},
	createAdmin: {
		url: '/admin/createAdmin',
		method: 'post',
		description: '创建管理员'
	},
	getAdminDetail: {
		url: '/admin/getAdminDetail',
		method: 'get',
		description: '获取管理员详情'
	},
	updateAdmin: {
		url: '/admin/updateAdmin',
		method: 'post',
		description: '修改管理员'
	},
	updateAdminStatus: {
		url: '/admin/updateAdminStatus',
		method: 'post',
		description: '修改管理员状态'
	},
	deleteAdmin: {
		url: '/admin/deleteAdmin',
		method: 'post',
		description: '删除管理员'
	},
	getAdminGroupList: {
		url: '/admin/getAdminGroupList',
		method: 'get',
		description: '获取管理员权限组'
	},
	createAdminGroup: {
		url: '/admin/createAdminGroup',
		method: 'post',
		description: '创建管理员权限组'
	},
	getAdminGroupDetail: {
		url: '/admin/getAdminGroupDetail',
		method: 'get',
		description: '获取权限组详情'
	},
	deleteAdminGroup: {
		url: '/admin/deleteAdminGroup',
		method: 'post',
		description: '删除权限组'
	},
	updateAdminGroup: {
		url: '/admin/updateAdminGroup',
		method: 'post',
		description: '修改权限组'
	},
}
