<template>
	<div>
		<navList/>
		<headers/>
		<div :style="`width:${!empty(secondMenuList) ? 'calc(100% - 266px);' : 'calc(100% - 64px);'}`" class="content">
			<router-view :key="$route.fullpath" @prefix="submitTitlePrefix" @suffix="submitTitleSuffix" @asyncTitle="submitAsyncTitle" @title="submitTitle"/>
		</div>
	</div>
</template>

<script>
import navList from "../components/system/navList";
import headers from "../components/system/headers";
export default {
	name: "default",
	components: {
		'navList': navList,
		'headers': headers,
	},
	data() {
		return {
			reload: false,
			suffix: '',
			prefix: '',
		}
	},
	watch: {
		$route:async function (to, from) {
			let path = to.path;
			let fullPath = to.fullPath;
			let history = JSON.parse(JSON.stringify(this.$store.state.router.history));
			let tabBarList = JSON.parse(JSON.stringify(this.$store.state.router.tabBarList));
			let reload = false;
			let title = "";
			for (let i = history.length - 1; i >= 0; i--) {
				if(history[i].path === path){
					if( history[i].fullPath !== fullPath){
						reload = true;
					}
					break;
				}
			}
			for(let i=0;i<tabBarList.length;i++){
				if(tabBarList[i].name === fullPath){
					title = tabBarList[i].title;
					break;
				}
			}
			history.push({
				path:path,
				fullPath:fullPath,
				title:title,
			});
			await this.$store.dispatch('history', history);
			if(reload){
				this.reload = true;
				await this.$forceUpdate();
				this.reload = false;
			}else{
				if(title !== ''){
					let tabBarTitle = JSON.parse(JSON.stringify(this.$store.state.router.tabBarTitle));
					tabBarTitle.forEach((item, index) => {
						if (item === "···" && title !== '') {
							tabBarTitle[index] = title;
							this.$store.dispatch('tabBarTitle', tabBarTitle);
						}
					});
				}
			}
		},
	},
	computed: {
		secondMenuList: function () {
			return this.$store.state.router.secondMenuList;
		},
	},
	methods: {
		submitTitle: function (title) {
			let tabBarList = JSON.parse(JSON.stringify(this.$store.state.router.tabBarList));
			let tabBarTitle = JSON.parse(JSON.stringify(this.$store.state.router.tabBarTitle));
			let suffix = this.suffix;
			let prefix = this.prefix;
			let currentIndex;
			let that = this;
			tabBarList.forEach((item, index) => {
				if (item.name === that.$route.fullPath) {
					tabBarList[index].title = prefix + title + suffix
					currentIndex = index;
				}
			})
			this.$store.dispatch('tabBarList', tabBarList);
			tabBarTitle.forEach((item, index) => {
				if (item === "···") {
					tabBarTitle[index] = prefix + title + suffix
				}
			});
			this.$store.dispatch('tabBarTitle', tabBarTitle);
		},
		submitAsyncTitle: function (title) {
			this.$store.dispatch('asyncTitle', title)
		},
		submitTitleSuffix: function (suffix) {
			this.suffix = suffix;
		},
		submitTitlePrefix: function (prefix) {
			this.prefix = prefix;
		}
	}
}
</script>

<style lang="scss">
body {
	background: #f6f8f9;
}

.content {
	transition: all .3s;
	position: fixed;
	right: 0;
	bottom: 0;
	width: calc(100% - 266px);
	height: calc(100% - 110px);
	padding: 20px;
	overflow: auto;

	::-webkit-scrollbar-thumb {
		background-color: #eaeaeb;
		border: 3px solid transparent;
		border-radius: 7px;
		width: 2px;
	}

	.el-pagination{
		text-align: center;
		margin: 20px auto;
	}
}

.panel-main{
	background: #fff;
}

.panel-default{
	padding: 20px;
	min-height: 100%;
	.operation{
		margin: 0 0 20px 0;
		min-height: 32px;
		.right{
			float: right;
		}
	}
}

.panel-form{
	padding:40px 20px;
}
</style>
