import api from "@/request/index";
export default class request {
    constructor($axios) {
        this.axios = $axios;
        this.apisMap = apiMap;
    }
    send = (modelRule = 'app/class/rule', params = {}, async = false) =>  {
        let r = modelRule.split('/');
        let app = '', controller = '', rule = '';
        if (r.length === 3) {
            app = r[0];
            controller = r[1];
            rule = r[2];
        }
        if (r.length === 2) {
            app = 'admin';
            controller = r[0];
            rule = r[1];
        }
        let That = this;
        if (api[app][controller] && api[app][controller][rule]) {
            let resource = api[app][controller][rule];
            return That[resource['method']](params, resource, app);
        } else {
            console.log('接口不存在',app,controller,rule)
            return new Promise(function (resolve, reject){
                resolve('接口不存在');
            })
        }
    };
    post = (params, resource, app) => {
        let That = this;
        return new Promise(function (resolve, reject) {
            That.axios.post(That.createAddress(resource.url, app), params).then(res => {
                res = res.data;
                resolve(res)
            }).catch(err => {
                reject(err);
            })
        })
    };
    get = (params, resource, app) => {
        let That = this;
        return new Promise(function (resolve, reject) {
            That.axios.get(That.createAddress(resource.url, app), {params: params}).then(res => {
                res = res.data;
                resolve(res)
            }).catch(err => {
                reject(err);
            })
        })

    }
    url = (modelRule = 'app/model/rule') => {
        let r = modelRule.split('/');
        let app = '', controller = '', rule = '';
        if (r.length === 3) {
            app = r[0];
            controller = r[1];
            rule = r[2];
        }
        if (r.length === 2) {
            app = 'admin';
            controller = r[0];
            rule = r[1];
        }
        let That = this;
        if (api[app][controller] && api[app][controller][rule]) {
            return this.createAddress(api[app][controller][rule].url, app)
        } else {
            console.log('接口不存在',app,controller,rule)
            return new Promise(function (resolve, reject){
                resolve('接口不存在');
            })
        }
    }
    root = () => {
        return this.apisMap.api;
    }

    createAddress = (url, app) => {
        let root = this.root();
        return `${root}/${app}${url}`;
    }
}
