<template>
	<router-view/>
</template>

<script>
export default {
    name: "default"
}
</script>

<style scoped>

</style>