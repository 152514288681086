const adminRequest = require.context('@/request/admin', false, /^.*\.js$/);
let adminAll = {};
adminRequest.keys().forEach(fileName => {
    adminAll[fileName.replace(/^\.\/(.*)\.\w+$/, '$1')] = adminRequest(fileName).default;
});



export default {
    admin:adminAll,
}
