import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

let routes = []
const allPlugins = require.context('@/router/data', false, /^.*\.js$/);
allPlugins.keys().forEach(fileName => {
	routes.push(...allPlugins(fileName).default);
});

export default new Router({
	mode: 'history',
	routes: [
		...routes
	]
})
