
import axios from "axios";
import Vue from "vue";

Vue.prototype.$axios = axios;

Vue.prototype.$axios.interceptors.request.use(config => {
	let id = Vue.prototype.$cookie.get('id');
	let time = Vue.prototype.$cookie.get('time');
	let token = Vue.prototype.$cookie.get('token');
	let auth = Vue.prototype.$cookie.get('auth');
	if(Vue.prototype.empty(id)){
		id = "";
	}
	if(Vue.prototype.empty(time)){
		time = "";
	}
	if(Vue.prototype.empty(token)){
		token = "";
	}
	if(Vue.prototype.empty(auth)){
		auth = "";
	}
	config.headers.common['s-version'] = "v1";
	config.headers.common['s-token'] = token;
	config.headers.common['s-time'] = time;
	config.headers.common['s-auth'] = auth;
	config.headers.common['s-id'] = id;
	return config;
}, error => {
	return error;
});

Vue.prototype.$axios.interceptors.response.use(response => {
	let res = response.data;
	switch (res.code) {
		case 300:
			Vue.prototype.$message.warning(res.data)
			break;
		case 400:
			location.replace(Vue.prototype.buildUrl("/login/",{
				location:location.pathname
			}));
			break;
		case 500:
			Vue.prototype.$message.warning(res.data)
			break;
		case 600:
			Vue.prototype.$message.warning('暂无权限访问')
			break;
		default:
			break;
	}
	return response;
}, error => {
	console.log(error)
	// 下列是错误示范,死都不要写，主要是 error.response.data,有可能data不存在,报错半天死都找不到
	// return Promise.reject(error.response.data);
});
