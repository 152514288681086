export default [
	{
		path: '/user/',
		name: 'user-index',
		component: () => import('@/views/user/index')
	},
	{
		path: '/user/log/',
		name: 'user-log-index',
		component: () => import('@/views/user/log/index')
	},
]
