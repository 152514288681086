<template>
	<div class="nav" :style="`width:${!empty(secondMenuList) ? 266 : 64}px`">
		<div class="nav-first">
			<div class="logo">
				<img src="/static/img/public/logo.png" alt="">
			</div>
			<div class="nav-box">
				<div class="nav-list">
					<ul>
						<li @click="checkFirst(index)" :class="{active:index === activeIndex}"
						    v-for="(item,index) in menuList">
							<a href="javascript:;">
								<i :class="item.icon"/>
								<span>{{ item.name }}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="nav-second" v-if="!empty(secondMenuList)">
			<div class="head">
				<div class="title" style="color: #1890ff">
					北京天元
				</div>
				<el-divider class="el-divider" content-position="center">管理</el-divider>
			</div>
			<el-menu :default-active="routePath" class="el-menu-nav">
				<template v-for="(list,index) in secondMenuList">
					<el-menu-item @click="pushRoute(list)" v-if="empty(list.childList)" :index="list.path">
						<i :class="list.icon"></i>
						<span slot="title">{{ list.name }}</span>
					</el-menu-item>
					<el-submenu v-else :index="list.path">
						<template slot="title">
							<i :class="list.icon"></i>
							<span>{{ list.name }}</span>
						</template>
						<template v-for="(two,twoIndex) in list.childList">
							<el-menu-item @click="pushRoute(two)" v-if="empty(two.childList)" :index="two.path">
								{{ two.name }}
							</el-menu-item>
							<el-submenu v-else :index="two.path">
								<template slot="title">{{ two.name }}</template>
								<el-menu-item @click="pushRoute(three)" v-for="(three,threeIndex) in two.childList"
								              :index="three.path">{{ three.name }}
								</el-menu-item>
							</el-submenu>
						</template>
					</el-submenu>
				</template>
			</el-menu>
		</div>
	</div>
</template>

<script>
export default {
	name: "navList",
	data() {
		return {
			routePath:"",
		}
	},
	watch: {
		$route: function () {
			this.$store.dispatch('tabBarTitle', []);
			this.$store.dispatch('suffix', "");
			this.$store.dispatch('prefix', "");
			this.checkDefaultNav();
			this.$forceUpdate();
		},
	},
	computed: {
		activeIndex: function () {
			return this.$store.state.router.activeIndex;
		},
		menuList: function () {
			return this.$store.state.config.menuList;
		},
		secondMenuList: function () {
			return this.$store.state.router.secondMenuList;
		},
	},
	created() {
		this.routePath = this.$route.path;
	},
	mounted() {
		this.checkDefaultNav();
	},
	methods: {
		checkFirst: function (index) {
			let menu = this.menuList;
			this.$store.dispatch('activeIndex', index);
			this.$store.dispatch('secondMenuList', menu[index].childList);
			if (this.empty(menu[index].childList)) {
				this.pushRoute(menu[index])
			}
		},
		pushRoute: function (path) {
			if (this.$route.fullPath !== path.path) {
				this.$router.push({
					path: path.path
				});
			}
			this.pushTabBar(path);
		},
		pushTabBar: function (path) {
			let arr = JSON.parse(JSON.stringify(this.$store.state.router.tabBarList));
			let isIn = false;
			arr.forEach((item, index) => {
				if (item.name === path.path) {
					isIn = true;
				}
			});
			if (!isIn) {
				arr.push({
					name: path.path,
					title: path.name,
				});
			}
			this.$store.dispatch('tabBarList', arr);
			this.$store.dispatch('tabBarActive', path.path);

		},
		checkDefaultNav: function () {
			let path = this.checkData(this.$route.fullPath, this.menuList);
			if (this.empty(path)) {
				let r = this.routePath.split("/");
				console.log(r)
				let p = "";
				for(let i=0;i<r.length - 1;i++){
					p += r[i]+"/"
				}
				this.routePath = p;



				path = {
					path: this.$route.fullPath,
					name: this.$store.state.router.asyncTitle,
				}
				let n = JSON.parse(JSON.stringify(this.$store.state.router.tabBarTitle));
				n.push(path.name)
				this.$store.dispatch('tabBarTitle', n)
			}
			this.pushTabBar(path);
		},
		checkData: function (path, arr, start = 0) {
			let nTemp = [];
			let that = this;
			let data = [];
			let die = false;
			let cPath = "";
			let temp = path.split("/");
			temp.forEach((item, index) => {
				if (item !== '') {
					nTemp.push(item)
				}
			})
			for (let i = 0; i <= start; i++) {
				cPath += "/" + nTemp[i]
			}
			start++
			arr.forEach((item, index) => {
				if (item.path === path) {
					data = item;
					die = true;
					let n = that.$store.state.router.tabBarTitle;
					n.push(item.name)
					if (start === 1) {
						that.checkFirst(index);
					}
				} else if (item.path === cPath) {
					if (start === 1) {
						that.checkFirst(index);
					}
					if (die === false) {
						let n = that.$store.state.router.tabBarTitle;
						n.push(item.name)
						that.$store.dispatch('tabBarTitle', n)
						data = that.checkData(path, item.childList, start)
					}
				}
			})
			return data;
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep{
	.el-menu {
		.el-submenu__title {
			border-radius: 5px;
		}

		li {
			border-radius: 5px;
			margin: 0 auto;
		}

		li span {
			transition: all .3s;
		}

		li:hover span {
			color: #409EFF;
		}

		li:hover .el-submenu__icon-arrow {
			color: #409EFF;
		}

		.el-menu:not(.el-menu--inline) > li > .el-submenu__title {
			padding: 0 10px !important;
		}

		.is-opened .is-active[tabindex="-1"] {
			background: #ecf5ff;
		}

		.el-menu-item {
			margin: 5px 0;
		}

		.el-menu-item, .el-submenu__title,
		.el-submenu .el-menu-item, {
			height: 45px;
			line-height: 45px;
		}

		.el-submenu .el-menu-item {
			min-width: auto;
		}
	}
}

.nav {

	transition: width .2s;
	user-select: none;
	background: #fff;
	font-size: 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 266px;
	height: 100%;

	.nav-first {
		display: inline-block;
		position: relative;
		width: 64px;
		height: 100%;
		background: #282c34;

		.logo {
			position: absolute;
			left: 0;
			top: 0;
			width: 64px;
			height: 64px;

			img {
				width: 100%;
			}
		}

		.nav-box {
			width: 100%;
			display: table;
			position: absolute;
			top: 64px;
			left: 0;
			height: calc(100% - 64px);

			.nav-list {
				width: 100%;
				display: table-cell;
				vertical-align: middle;

				ul {
					display: block;
					width: 100%;

					li {
						position: relative;
						width: 100%;
						display: block;
						margin: 25px 0;

						a {
							i{
								display: block;
								text-align: center;
								color: #fff;
								font-size: 16px;
							}
							span {
								font-size: 14px;
								margin-top: 5px;
								text-align: center;
								display: block;
								color: #fff;
							}
						}
					}

					.active a {
						background: transparent !important;
					}

					.active a:after {
						position: absolute;
						right: 0;
						width: 0;
						height: 0;
						top: calc((100% - 12px) / 2);
						overflow: hidden;
						content: "";
						border-color: transparent #fff transparent transparent;
						border-style: solid dashed dashed;
						border-width: 8px;
					}
				}

			}
		}
	}

	.nav-second::-webkit-scrollbar {
		width: 0;
	}

	.nav-second {
		border-right: solid 1px #e6e6e6;
		overflow: auto;
		display: inline-block;
		position: relative;
		width: 202px;
		height: 100%;

		.head {
			.title {
				line-height: 55px;
				text-align: center;
				font-size: 20px;
				color: #515a6e;
			}

			.el-divider {
				font-size: 12px;
				margin: 7px 0;
				color: #515a6e;
			}
		}

		.el-menu-nav {
			width: 90%;
			margin: 0 auto;
			padding: 10px 0;
			border: 0;
		}
	}
}
</style>
