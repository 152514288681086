export default {
	state:{
	},
	actions:{

	},
	mutations:{

	},
	getters:{

	}
}