export default [
	{
		path: '/authority/admin/',
		name: 'authority-admin-index',
		component: () => import('@/views/authority/admin/index'),
	},
	{
		path: '/authority/admin/create',
		name: 'authority-admin-create',
		component: () => import('@/views/authority/admin/create')
	},
	{
		path: '/authority/admin/detail',
		name: 'authority-admin-detail',
		component: () => import('@/views/authority/admin/detail'),
	},
	{
		path: '/authority/group/',
		name: 'authority-group-index',
		component: () => import('@/views/authority/group/index')
	},
	{
		path: '/authority/group/create',
		name: 'authority-group-create',
		component: () => import('@/views/authority/group/create')
	},
	{
		path: '/authority/group/detail',
		name: 'authority-group-detail',
		component: () => import('@/views/authority/group/detail')
	},
]
